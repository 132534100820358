/* Container Styling */
.positions-container {
    margin: 0 auto;
    max-width: 800px; /* Keep a reasonable limit on larger screens */
    background-color: #f9f9f9;
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Table Styling */
.positions-container table {
    width: 100%; /* Use the full width of the container */
    border-collapse: collapse;
    table-layout: fixed;
    font-family: Arial, sans-serif;
    font-size: 1rem;
    color: #333;
    
}

.positions-container thead {
    background-color: #002C54;
    color: white;
}

.positions-container thead th {
    /* padding: 10px; */
    /* height: 50px; */
    /* text-align: center; */
    /* vertical-align: middle;  */
    position: sticky;
    top: 0;
    height: 50px;
    z-index: 2;
    background-color: #002C54;
}

.positions-container tbody tr {
    border-bottom: 1px solid #ddd;
}

.positions-container tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.positions-container tbody td {
    /* padding: 10px; */
    /* text-align: center;  */
    /* vertical-align: middle; */
    padding-top: 10px;
    padding-bottom: 10px;
}
.profitLossClass {

}

.positions-container tbody tr:hover {
    background-color: #335E85; /* Lighter version of #002C54 */
    color: white; /* Optional: Adjust text color for better contrast */
}
.positions-container tbody tr:hover a {
    color: white;
}
.positions-container tbody tr:hover .positive, tbody tr:hover .negative {
    color: white;
}
/* Unrealized P/L Colors */
.positive {
    color: green; /* Green for positive values */
}

.negative {
    color: red; /* Red for negative values */
}

.neutral {
    color: black; /* Black for zero */
}

.positions-container input[type="checkbox"] {
    transform: scale(2);
}
/* Responsive Styling */
@media (max-width: 768px) {
    
}
