@media only screen and (max-width: 600px) {
   .component {
      /* width: 99vw; */
   }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {
   .component {
      display: block;
      margin-left: auto;
      margin-right: auto;
      /* width: 30vw; */
   }
}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {
   .component {
      display: block;
      margin-left: auto;
      margin-right: auto;
      /* width: 50vw; */
   }
}

.home-container {
   max-width: 800px;
   margin-left: auto;
   margin-right: auto;
   border-left: 1px black solid;
   border-right: 1px black solid;
}

.joint-component {
   display: flex;
   justify-content: center;
   
}

.component {
   /* border-bottom: 1px gray solid; */
   height: auto;
   text-align: center;
}
.component a {
   text-decoration: none;
   color: black;
}


