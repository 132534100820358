.botparameters-container {
    width: 50%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.botparameters-container h2 {
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #333;
}
.botparameters-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.botparameters-container form div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* gap: 10px; */
    width: 100%;
    padding-right: 10px;
}

.botparameters-container form div label {
    flex: 1; 
    text-align: right;
    margin-right: 14px;
    font-weight: bold;
    color: #555;
}

/* Inputs */
.botparameters-container form div input {
    font-size: 1rem;
    border: 1px solid #ccc;
    transition: border-color 0.2s;
    height: 28px;
    width: 80px;
    text-align: center;
    background-color: #fff;
}
.botparameters-container form div input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Selects */
.botparameters-container form div select {
    font-size: 1rem;
    border: 1px solid #ccc;
    transition: border-color 0.2s;
    height: 28px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
}
/* Hour selector */
.time-parameter-hour {
    width: 60px;  
    margin: 0 !important;
    padding: 0;
}
/* Minute selector */
.time-parameter-minute {
    width: 60px;  
    margin: 0 !important;
    padding: 0;
}
.time-parameter-period {
    border: solid 1px #ccc;
    height: 24px;
    width: 50px;
    display: flex;
    justify-content: center;
    padding-top: 2px;
    background-color: #fff;
    cursor: default;
}

.botparameters-container form div select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Submit button */
.botparameters-submit-button {
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    width: 100px;
}
.botparameters-submit-button:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.botparameters-submit-button:active {
    background-color: #004085;
}
.botparameters-submit-button:disabled {
    background-color: #ccc;  /* Light gray background */
    cursor: not-allowed;     /* Cursor style to indicate unclickable */
    opacity: 0.7;           /* Slightly reduce opacity if you like */
}

/* Errors container */
.botparameters-errors-container div {
    display: flex;
    flex-flow: column wrap;
    gap: 10px;
    color: red;
}

/* Success container */
.botparameters-success-container div {
    display: flex;
    flex-flow: column wrap;
    color: green;
}