@media only screen and (max-width: 600px) {
    .searchstock input {
        width: 30vw;
        
    }
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {
    .searchstock input {
        width: 10vw;
        
    }
}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}

.searchstock {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 30px;
    height: 120px;
    width: 100%;
    border: solid 1px black;
}

.searchstock input {
    height: 30px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}