/* Responsive styling */
@media (max-width: 600px) {
    .modal-content {
        padding: 20px;
    }

    h2 {
        font-size: 20px;
    }

    input,
    button[type="submit"] {
        font-size: 14px;
    }
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

nav {
    background: #002C54;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
}

nav .nav-button {
    height: 100px;
    width: 140px;
    float: right;
    background-color: #FDF6F6;
    border: none;
    border-left: solid 4px #002C54;
}

/* Modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.login h2, .signup h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
}

/* Form styling */
.login form, .signup form {
    display: flex;
    flex-direction: column;
}

.login label, .signup label {
    margin-bottom: 5px;
    font-weight: 600;
    color: #555;
}

.login input, .signup input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.login input:focus, .signup input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

.login button[type="submit"], .signup button[type="submit"] {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login button[type="submit"]:hover, .signup button[type="submit"]:hover {
    background-color: #0056b3;
}