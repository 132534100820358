.patron-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: solid 1px black;
    max-width: 800px;
    height: 380px;
    margin: auto;
}

.patron {
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Place title at the top and subtitle at the bottom */
    align-items: center; /* Center elements horizontally */
    height: 100%;
    border: solid 1px black;
    min-width: 380px;
}

.patron-title, .patron-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 40px;
    font-weight: bold;
    font-size: 20px;
}


.patron-picture {
    flex-grow: 1; /* Allow the picture to take up the remaining space */
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px gray; /* Optional: Add a border for visibility */
    width: 100%;
}
