@media screen and (max-width: 768px) {
    /* Styles for medium-sized devices */
    .status-container {
        width: 40%;
        display: flex;
        flex-flow: row wrap;
    }
}

.status-container {
    width: 40%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    border: solid 1px black;    
}

.status-container div {
    display: flex;
    flex-flow: row nowrap;
}
.status-container div h2 {
    width: 20px;
    text-wrap: wrap;
}

.status-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin-top: 18px;
    margin-left: 80px;
    margin-right: 12px;
}

.status-indicator.up {
    background-color: #03ff3a; /* LimeGreen for "up" or "open" */
    box-shadow: 0 0 15px 5px rgba(3, 255, 58, 0.5); /* Lighter green glow */
}

.status-indicator.down {
    
    background-color: red; /* Red for "down" or "closed" */
    box-shadow: 0 0 15px 5px rgba(255, 0, 0, 0.5); /* Red glow */
}

.status-indicator.neutral {
    background-color: #1E90FF; /* DodgerBlue for "null" */
    box-shadow: 0 0 15px 5px rgba(30, 144, 255, 0.5); /* Blue glow */
}