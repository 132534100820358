/* Customize the scrollbar for the main browser window */
html::-webkit-scrollbar {
    width: 60px;
    /* Width of the scrollbar */
}

html::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the scrollbar track */
}

html::-webkit-scrollbar-thumb {
    background-color: #002C54;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounding the corners of the thumb */
    border: 3px solid #f1f1f1;
    /* Optional: space around the thumb */
}

html::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker color when hovering */
}

/* Optional: Customize the scrollbar's corner */
html::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
    /* Background color of the scrollbar corner */
}

html, body {
    overflow-x: hidden; /* This hides the horizontal scrollbar */
}
