.total-equity {
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 1rem;
    text-align: left;
    width: 60%;
    border: solid 1px black;
}

.total-equity table {
    width: 100%;
    border-collapse: collapse;
}

.total-equity th {
    text-align: left;
    padding: 5px 10px;
    font-weight: bold;
}

.total-equity td {
    
    text-align: right;
}

.total-equity td:last-child {
    text-align: left; /* Align percentages next to their values */
    font-size: 0.9rem;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .total-equity {
        width: 100%;
        
    }
}