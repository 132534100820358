.alpacalink-container {
    font-size: 20px;
    border: solid 1px black;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
}
.alpacalink-container button {
    background-color: #FFD700; /* Gold color */
    color: #654321; /* Dark brown text color */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 10px 40px; /* Top-bottom and left-right padding */
    font-size: 16px; /* Adjust font size */
    font-family: Arial, sans-serif; /* Font family */
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 0px 10px #FFFFE0 inset; /* Outer and inner shadows */
    cursor: pointer; /* Pointer on hover */
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
}
.alpacalink-container button:hover {
    background-color: #FFC107; /* Slightly darker shade on hover */
}
.alpacalink-container button:active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 8px #FFFFE0 inset; /* Subtle change on click */
}