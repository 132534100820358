.company-title {
    text-align: center;
    max-width: 800px;
    margin: auto;
  }
  .company-title a {
      text-decoration: none;
      color: black;
  }
  .company-title a:hover {
      cursor: default;
  }