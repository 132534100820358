.watchlist-container {
    width: 50%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
}

.watchlist-container h2 {
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #333;
}

/* Add watchlist item */
.add-watchlist-item-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.add-watchlist-item-container input {
    font-size: 14px;
    border: 1px solid #ccc;
    text-align: center;
    height: 28px;
    width: 60px;
}
.add-watchlist-item-container input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.add-watchlist-item-container button {
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    cursor: pointer;
    align-self: center;
    height: 30px;
    width: 26px;
}
.add-watchlist-item-container button:hover {
    background-color: #0056b3;
}

/* Watchlist items */
.watchlist-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    
}
.watchlist-items div {
    display: flex;
    flex-flow: row nowrap;
   
}
.watchlist-items div input {
    font-size: 14px;
    border: 1px solid #ccc;
    text-align: center;
    height: 32px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.watchlist-items div input:hover {
    background-color: #335E85;
    color: white;
    cursor: pointer;
}
.watchlist-items div input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.watchlist-items div button {
    font-size: 16px;
    color: white;
    background-color: red;
    border: none;
    cursor: pointer;
    align-self: center;
    height: 32px;
    width: 26px;
}
.watchlist-items div button:hover {
    background-color: #335E85;
}
.watchlist-items div .watchlist-hint {
    border: solid 1px #ccc5c5;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    height: 28px;
    width: 60px;
}
