@media only screen and (max-width: 600px) {
  /* .optionchain {
    width: 30%;
    max-width: 170px;
  } */
}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

.stock-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px black solid;
    border-right: 1px black solid;
}


.company-info {
  margin-bottom: 20px;
}



.prediction-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 800px;
  border: solid 1px black;
}

.prediction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* border: solid 1px black; */
  padding: 4px;
}





.optionchain-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  height: 400px;
  overflow-y: scroll;
  max-width: 800px;
  margin: auto;
  border: solid 1px black;
}

.optionchain-container::-webkit-scrollbar {
  width: 60px;
  border: solid 1px black;
}

.optionchain-container::-webkit-scrollbar-thumb {
  background-color: #002C54;
  height: 60px;
}

.optionchain-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.optionchain {
  background-color: #ffffff;
  /* border-radius: 1px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}

.optionchain:hover {
  /* transform: translateY(-10px); */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.lowcostoptions-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px black;
}