.timeframes-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
    margin-right: 4px;
    gap: 4px;
}
.timeframes-container button {
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    width: 80px;
}
.timeframes-container button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}