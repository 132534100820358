.volatility-container {
    font-size: 20px;
    border: solid 1px black;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
 }